"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.MarkStyle = void 0;
var slate_1 = require("slate");
var MarkStyle_1 = require("./MarkStyle");
Object.defineProperty(exports, "MarkStyle", { enumerable: true, get: function () { return MarkStyle_1.MarkStyle; } });
exports.Text = {
    isText: function (node) {
        return slate_1.Text.isText(node);
    },
};
