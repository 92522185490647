import "../CommonFrontend/Public/Widgets/AccessAuthentication/AccessAuthentication.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/AccessAuthentication/AccessAuthentication.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUy27bMBC8+yv2UsABSleSE9tlLv2HfoBBiyuJKR8CubLlFP73QoxcyVGcGAFyXY1mZpe7s9hu8tr4ZQJ/ZwCFs8QKYZQ+csiFVjuPTGMp8uPjDKAWUipbctgLP2cs1CJXtmRmu013ev30lKZ/7jpchaqsiEOaJPuqK0gVai2OHEqvZFcoRf2aRQ8sOrIIrUrLFKEJHAIJT131qQmkiiPLnSW0xCFHS+gjqVeSEZpaC0KWO90YGzgYZY1o58l3uE+Sur2bQr07BA5p4UE05EadMnJ118ZLF26PvtDuwI68B55mi35+aZyfES07KEkVf9F6jDVfKssh+U8+nUbnRngUHFL4AemVCU3m3EulSfJt7CWLXs5uOVRKSrRjxDIiprID4n5A+I4jG1phO0fkzAfvN1A9fCi2iohPLcmVlWxHQHPhZv3lWgCELbG4vsN6Dg42Vxy8vb3dVmaFnxzElPfnZzsbUyyid2XrhrZbLXaoI2uskhc2FM4bDo0NGM8xJkZQz3jW6Aq/1TOy+PNIKmvibDQSoT9b4ZCM9UUUy512nsOhUoTjr7v+xuISaiyIA0uz7soGTD7YvfoA8gYMXg6z0Ni+mz6n2S+DUgmYG2XPGbCOeROZFhc5+ypgsj5gTmMa0bJzjm5Wy5t40of3edar9W08Yz+hqWvnKcB8FOtyX73Nc4npSf4BbbeWL2UGAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var wrapper = '_8cpmr30';
export var container = '_8cpmr31';
export var card = '_8cpmr32';
export var mainCrossFadeWrapper = '_8cpmr33';
export var securedByQwilr = '_8cpmr34';
export var spinner = '_8cpmr35';
export var stackGapL = '_8cpmr36';
export var stackGapLTextCentre = '_8cpmr37';
export var formHeader = '_8cpmr38';
export var form = '_8cpmr39';
export var primaryButtonText = '_8cpmr3a';
export var backButton = '_8cpmr3b';
export var disclaimer = '_8cpmr3c';
export var logInCta = '_8cpmr3d';
export var resendEmailButton = '_8cpmr3e';