"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createParagraphToken = exports.ParagraphConfig = void 0;
exports.ParagraphConfig = {
    type: "paragraph",
    tokenType: {},
    publicRender: function (_a) {
        var attributes = _a.attributes, innerHtml = _a.innerHtml;
        if (innerHtml === "" || /^<span[^>]*><\/span>$/.test(innerHtml)) {
            innerHtml = "<br />";
        }
        return "<p class=\"".concat(attributes.className, "\">").concat(innerHtml, "</p>");
    },
    traits: {
        spacingShim: {
            className: "p-widget__spacing-shim",
        },
    },
};
var createParagraphToken = function () { return ({
    type: "block",
    subType: "paragraph",
}); };
exports.createParagraphToken = createParagraphToken;
