"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayNumber = exports.createHtmlFromListItem = void 0;
var List_1 = require("../Models/List");
var lodash_1 = require("lodash");
var ListItem_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/ListItem");
var createHtmlFromListItem = function (token, _a) {
    var innerHtml = _a.innerHtml, index = _a.index, tokens = _a.tokens;
    if (token.listType == List_1.ListItemType.Bullet) {
        return createBulletListItem(token, innerHtml !== null && innerHtml !== void 0 ? innerHtml : "", index !== null && index !== void 0 ? index : 0, tokens !== null && tokens !== void 0 ? tokens : []);
    }
    else {
        return createOrderedListItem(token, innerHtml !== null && innerHtml !== void 0 ? innerHtml : "", index !== null && index !== void 0 ? index : 0, tokens !== null && tokens !== void 0 ? tokens : []);
    }
};
exports.createHtmlFromListItem = createHtmlFromListItem;
var getDisplayNumber = function (itemNumber, indentLevel) {
    var numberType = (indentLevel - 1) % 3;
    switch (numberType) {
        case 0:
            return "".concat(itemNumber, ".");
        case 1:
            return "".concat(getLatinNumber(itemNumber), ".");
        case 2:
            return "".concat(getRomanNumber(itemNumber), ".");
        default:
            return "";
    }
};
exports.getDisplayNumber = getDisplayNumber;
var getOpenAndCloseTags = function (_a) {
    var token = _a.token, index = _a.index, tokens = _a.tokens;
    var getNextListItemFromPosition = function (direction) {
        var tokenSlice;
        var operation;
        if (direction === "backward") {
            tokenSlice = tokens.slice(0, index);
            operation = lodash_1.last;
        }
        else {
            tokenSlice = tokens.slice(index + 1);
            operation = lodash_1.first;
        }
        var blockTokens = tokenSlice.filter(function (t) { return t.type === "block"; });
        var nextToken = operation(blockTokens);
        return !!nextToken && nextToken.subType === "listItem" ? nextToken : undefined;
    };
    var getNextListItemAtLevel = function (level, direction) {
        var _a, _b;
        var findListItemAtLevel = function (tokenSlice, level) {
            var e_1, _a;
            try {
                for (var tokenSlice_1 = __values(tokenSlice), tokenSlice_1_1 = tokenSlice_1.next(); !tokenSlice_1_1.done; tokenSlice_1_1 = tokenSlice_1.next()) {
                    var token_1 = tokenSlice_1_1.value;
                    var isListItem = token_1.type === "block" && token_1.subType === "listItem";
                    if (isListItem && token_1.level === level) {
                        return token_1;
                    }
                    else if (isListItem && token_1.level < level) {
                        break;
                    }
                    else if (token_1.type === "block" && token_1.subType !== "listItem") {
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (tokenSlice_1_1 && !tokenSlice_1_1.done && (_a = tokenSlice_1.return)) _a.call(tokenSlice_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        };
        if (direction === "forward") {
            return findListItemAtLevel(tokens.slice(index + 1), level);
        }
        else if (direction === "backward") {
            return findListItemAtLevel(tokens.slice(0, index).reverse(), level);
        }
        else {
            return ((_b = (_a = findListItemAtLevel(tokens.slice(0, index).reverse(), level)) !== null && _a !== void 0 ? _a : findListItemAtLevel(tokens.slice(index + 1), level)) !== null && _b !== void 0 ? _b : undefined);
        }
    };
    var buildTagsForToken = function (direction) {
        var _a;
        var tags = [];
        var referenceToken = token;
        while (true) {
            var nextToken = getNextListItemFromPosition(direction);
            var nextTokenAtLevel = getNextListItemAtLevel(referenceToken.level, direction);
            var nextTokenIsDeeper = (nextToken === null || nextToken === void 0 ? void 0 : nextToken.level) > referenceToken.level;
            var nextTokenAtLevelIsSameListType = (nextTokenAtLevel === null || nextTokenAtLevel === void 0 ? void 0 : nextTokenAtLevel.listType) === referenceToken.listType;
            var isOriginalToken = referenceToken === token;
            if (direction === "forward" && !!nextToken && nextTokenIsDeeper) {
                break;
            }
            else if (direction === "backward" && !!nextTokenAtLevel && nextTokenAtLevelIsSameListType && !isOriginalToken) {
                break;
            }
            else if (direction === "forward" && !!nextTokenAtLevel && nextTokenAtLevelIsSameListType) {
                tags.push("li");
                break;
            }
            else if (direction === "backward" && !!nextTokenAtLevel && nextTokenAtLevelIsSameListType && isOriginalToken) {
                tags.push("li");
                break;
            }
            else if (direction === "backward" && !!nextToken && nextTokenIsDeeper && !nextTokenAtLevel) {
                tags.push("li");
                break;
            }
            tags.push("li");
            tags.push(referenceToken.listType === List_1.ListItemType.Bullet ? "ul" : "ol");
            if (referenceToken.level === 1) {
                break;
            }
            referenceToken =
                (_a = getNextListItemAtLevel(referenceToken.level - 1)) !== null && _a !== void 0 ? _a : (0, ListItem_1.createListItemToken)({ level: referenceToken.level - 1 });
        }
        return direction === "forward" ? tags : tags.reverse();
    };
    var tagsBefore = buildTagsForToken("backward");
    var openTags = (0, lodash_1.dropRight)(tagsBefore, 1)
        .map(function (root) { return "<".concat(root, ">"); })
        .join("");
    var tagsAfter = buildTagsForToken("forward");
    var closeTags = "".concat(tagsAfter.map(function (root) { return "</".concat(root, ">"); }).join(""));
    return { openTags: openTags, closeTags: closeTags };
};
var createBulletListItem = function (token, innerHtml, index, tokens) {
    var attributeContents = ["class=\"unordered level-".concat(token.level, " project-block__block-element\"")].join(" ");
    var _a = getOpenAndCloseTags({ token: token, index: index, tokens: tokens }), openTags = _a.openTags, closeTags = _a.closeTags;
    return "".concat(openTags, "<li ").concat(attributeContents, "><span>").concat(innerHtml, "</span>").concat(closeTags);
};
var createOrderedListItem = function (token, innerHtml, index, tokens) {
    var classes = ["ordered", "project-block__block-element"];
    var itemNumber = token.startCounter || 1;
    for (var i = index - 1; i >= 0; i--) {
        var currToken = tokens[i];
        if (currToken.type == "block") {
            if (currToken.subType == "listItem" && currToken.listType == "number" && currToken.level == token.level) {
                if (currToken.startCounter != null) {
                    itemNumber += currToken.startCounter;
                }
                else {
                    ++itemNumber;
                }
            }
            if (currToken.subType != "listItem" || currToken.listType != "number" || currToken.level < token.level) {
                break;
            }
        }
    }
    var attributeContents = "class=\"".concat(classes.join(" "), "\"");
    var displayNumber = (0, exports.getDisplayNumber)(itemNumber, token.level);
    var _a = getOpenAndCloseTags({ token: token, index: index, tokens: tokens }), openTags = _a.openTags, closeTags = _a.closeTags;
    return "".concat(openTags, "<li ").concat(attributeContents, "><span class=\"before\">").concat(displayNumber, "</span><span>").concat(innerHtml, "</span>").concat(closeTags);
};
var getLatinNumber = function (itemNumber) {
    var result = "";
    var currValue = itemNumber;
    while (currValue >= 1) {
        var digit = (currValue - 1) % 26;
        currValue = Math.floor((currValue - 1) / 26);
        result = String.fromCharCode(digit + 97) + result;
    }
    return result;
};
var getRomanNumber = function (itemNumber) {
    var initialResult = getRawRomanConversion(itemNumber);
    return addRomanShorthands(initialResult);
};
var getRawRomanConversion = function (itemNumber) {
    var currVal = itemNumber;
    var result = "";
    while (currVal >= 1) {
        if (currVal > 1000) {
            result += "m";
            currVal -= 1000;
        }
        else if (currVal >= 500) {
            result += "d";
            currVal -= 500;
        }
        else if (currVal >= 100) {
            result += "c";
            currVal -= 100;
        }
        else if (currVal >= 50) {
            result += "l";
            currVal -= 50;
        }
        else if (currVal >= 10) {
            result += "x";
            currVal -= 10;
        }
        else if (currVal >= 5) {
            result += "v";
            currVal -= 5;
        }
        else {
            result += "i";
            currVal -= 1;
        }
    }
    return result;
};
var addRomanShorthands = function (romanNumber) {
    return romanNumber
        .replace("dcccc", "cm")
        .replace("cccc", "cd")
        .replace("lxxxx", "xc")
        .replace("xxxx", "xl")
        .replace("viiii", "ix")
        .replace("iiii", "iv");
};
