"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlockQuoteToken = exports.BlockQuoteConfig = void 0;
exports.BlockQuoteConfig = {
    type: "blockQuote",
    tokenType: {},
    publicRender: function (_a) {
        var attributes = _a.attributes, innerHtml = _a.innerHtml;
        if (innerHtml === "" || /^<span[^>]*><\/span>$/.test(innerHtml)) {
            innerHtml = "<br />";
        }
        return "<blockquote class=\"".concat(attributes.className, "\">").concat(innerHtml, "</blockquote>");
    },
    traits: {
        spacingShim: {
            className: "blockquote-widget__spacing-shim",
        },
    },
};
var createBlockQuoteToken = function () { return ({
    type: "block",
    subType: "blockQuote",
}); };
exports.createBlockQuoteToken = createBlockQuoteToken;
