"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTokenTraverser = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var Types_1 = require("@CommonFrontendBackend/ContentTokens/Types");
var GetCommonConfigByType_1 = require("@CommonFrontend/WidgetRegistry/Utils/GetCommonConfigByType");
var BlockTokenTraverser = (function () {
    function BlockTokenTraverser() {
    }
    BlockTokenTraverser.prototype.traverseBlockForTokens = function (block, _a) {
        var _b = _a === void 0 ? {} : _a, depth = _b.depth;
        var contentModels = this.getContentAreasForBlock(block);
        var tokens = this.givenContentAreasGetAllTokens(contentModels, { depth: depth });
        return tokens;
    };
    BlockTokenTraverser.prototype.processAndTransformTokens = function (block, transformFn) {
        var e_1, _a;
        try {
            for (var _b = __values(this.getContentAreasForBlock(block)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var contentArea = _c.value;
                if (contentArea.tokens) {
                    contentArea.tokens = this.getTransformedTokens(contentArea.tokens, transformFn);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    BlockTokenTraverser.prototype.getContentAreasForBlock = function (block) {
        var _a, _b, _c, section, _d, _e, item, e_2_1, e_3_1;
        var e_3, _f, e_2, _g;
        var _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return __generator(this, function (_w) {
            switch (_w.label) {
                case 0:
                    _a = block.type;
                    switch (_a) {
                        case BlockTypes_1.BlockType.Text: return [3, 1];
                        case BlockTypes_1.BlockType.Accept: return [3, 4];
                        case BlockTypes_1.BlockType.Agreement: return [3, 7];
                        case BlockTypes_1.BlockType.OldQuote: return [3, 10];
                        case BlockTypes_1.BlockType.Quote: return [3, 13];
                        case BlockTypes_1.BlockType.Splash: return [3, 31];
                        case BlockTypes_1.BlockType.Video: return [3, 33];
                        case BlockTypes_1.BlockType.Browser: return [3, 36];
                        case BlockTypes_1.BlockType.Source: return [3, 36];
                    }
                    return [3, 37];
                case 1:
                    if (!((_j = (_h = block.contentAreas) === null || _h === void 0 ? void 0 : _h.mainContent) !== null && _j !== void 0 ? _j : false)) return [3, 3];
                    return [4, block.contentAreas.mainContent];
                case 2:
                    _w.sent();
                    _w.label = 3;
                case 3: return [3, 37];
                case 4:
                    if (!((_l = (_k = block.contentAreas) === null || _k === void 0 ? void 0 : _k.main) !== null && _l !== void 0 ? _l : false)) return [3, 6];
                    return [4, block.contentAreas.main];
                case 5:
                    _w.sent();
                    _w.label = 6;
                case 6: return [3, 37];
                case 7:
                    if (!((_o = (_m = block.contentAreas) === null || _m === void 0 ? void 0 : _m.mainContent) !== null && _o !== void 0 ? _o : false)) return [3, 9];
                    return [4, block.contentAreas.mainContent];
                case 8:
                    _w.sent();
                    _w.label = 9;
                case 9: return [3, 37];
                case 10:
                    if (!((_q = (_p = block.contentAreas) === null || _p === void 0 ? void 0 : _p.header) !== null && _q !== void 0 ? _q : false)) return [3, 12];
                    return [4, block.contentAreas.header];
                case 11:
                    _w.sent();
                    _w.label = 12;
                case 12: return [3, 37];
                case 13:
                    if (!((_s = (_r = block.contentAreas) === null || _r === void 0 ? void 0 : _r.header) !== null && _s !== void 0 ? _s : false)) return [3, 15];
                    return [4, block.contentAreas.header];
                case 14:
                    _w.sent();
                    _w.label = 15;
                case 15:
                    _w.trys.push([15, 28, 29, 30]);
                    _b = __values((_t = block.sections) !== null && _t !== void 0 ? _t : []), _c = _b.next();
                    _w.label = 16;
                case 16:
                    if (!!_c.done) return [3, 27];
                    section = _c.value;
                    return [4, section.description];
                case 17:
                    _w.sent();
                    _w.label = 18;
                case 18:
                    _w.trys.push([18, 24, 25, 26]);
                    _d = (e_2 = void 0, __values(section.items)), _e = _d.next();
                    _w.label = 19;
                case 19:
                    if (!!_e.done) return [3, 23];
                    item = _e.value;
                    return [4, item.description];
                case 20:
                    _w.sent();
                    if (!("featureList" in item && item.featureList)) return [3, 22];
                    return [4, item.featureList];
                case 21:
                    _w.sent();
                    _w.label = 22;
                case 22:
                    _e = _d.next();
                    return [3, 19];
                case 23: return [3, 26];
                case 24:
                    e_2_1 = _w.sent();
                    e_2 = { error: e_2_1 };
                    return [3, 26];
                case 25:
                    try {
                        if (_e && !_e.done && (_g = _d.return)) _g.call(_d);
                    }
                    finally { if (e_2) throw e_2.error; }
                    return [7];
                case 26:
                    _c = _b.next();
                    return [3, 16];
                case 27: return [3, 30];
                case 28:
                    e_3_1 = _w.sent();
                    e_3 = { error: e_3_1 };
                    return [3, 30];
                case 29:
                    try {
                        if (_c && !_c.done && (_f = _b.return)) _f.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                    return [7];
                case 30: return [3, 37];
                case 31: return [4, block.contentAreas.main];
                case 32:
                    _w.sent();
                    return [3, 37];
                case 33:
                    if (!((_v = (_u = block.contentAreas) === null || _u === void 0 ? void 0 : _u.header) !== null && _v !== void 0 ? _v : false)) return [3, 35];
                    return [4, block.contentAreas.header];
                case 34:
                    _w.sent();
                    _w.label = 35;
                case 35: return [3, 37];
                case 36:
                    {
                    }
                    _w.label = 37;
                case 37: return [2];
            }
        });
    };
    BlockTokenTraverser.prototype.getTransformedTokens = function (tokens, transformFn) {
        var _this = this;
        return tokens.reduce(function (aggregateTokens, token) {
            var _a;
            var widgetTokenTraversers = (_a = (0, GetCommonConfigByType_1.getGenericCommonConfigByType)(token.subType)) === null || _a === void 0 ? void 0 : _a.tokenTraversers;
            if (widgetTokenTraversers) {
                return widgetTokenTraversers.getTransformedTokens(token, aggregateTokens, function (tokens) { return _this.getTransformedTokens(tokens, transformFn); }, function (token) { return _this.transformTokenToReplacementTokens(token, transformFn); });
            }
            switch (token.subType) {
                default: {
                    return __spreadArray(__spreadArray([], __read(aggregateTokens), false), __read(_this.transformTokenToReplacementTokens(token, transformFn)), false);
                }
            }
        }, []);
    };
    BlockTokenTraverser.prototype.transformTokenToReplacementTokens = function (token, transformFn) {
        var result = transformFn(token);
        if (result instanceof Array) {
            return result;
        }
        else if (result) {
            return [result];
        }
        else {
            return [token];
        }
    };
    BlockTokenTraverser.prototype.givenContentAreasGetAllTokens = function (contentAreas, _a) {
        var contentAreas_1, contentAreas_1_1, contentArea, e_4_1;
        var e_4, _b;
        var _c = _a === void 0 ? {} : _a, depth = _c.depth;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 5, 6, 7]);
                    contentAreas_1 = __values(contentAreas), contentAreas_1_1 = contentAreas_1.next();
                    _d.label = 1;
                case 1:
                    if (!!contentAreas_1_1.done) return [3, 4];
                    contentArea = contentAreas_1_1.value;
                    if (!contentArea.tokens) return [3, 3];
                    return [4, this.getFlatFromListOfTokens(contentArea.tokens, { depth: depth })];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    contentAreas_1_1 = contentAreas_1.next();
                    return [3, 1];
                case 4: return [3, 7];
                case 5:
                    e_4_1 = _d.sent();
                    e_4 = { error: e_4_1 };
                    return [3, 7];
                case 6:
                    try {
                        if (contentAreas_1_1 && !contentAreas_1_1.done && (_b = contentAreas_1.return)) _b.call(contentAreas_1);
                    }
                    finally { if (e_4) throw e_4.error; }
                    return [7];
                case 7: return [2];
            }
        });
    };
    BlockTokenTraverser.prototype.getFlatFromListOfTokens = function (tokens, _a) {
        var tokens_1, tokens_1_1, token, widgetTokenTraversers, _b, _c, visitedToken, e_5_1, _d, _e, _f, column1Token, e_6_1, _g, _h, column2Token, e_7_1, _j, _k, row, row_1, row_1_1, cell, _l, _m, cellToken, e_8_1, e_9_1, e_10_1, e_11_1;
        var e_11, _o, e_5, _p, e_6, _q, e_7, _r, e_10, _s, e_9, _t, e_8, _u;
        var _v;
        var _w = _a === void 0 ? {} : _a, depth = _w.depth;
        return __generator(this, function (_x) {
            switch (_x.label) {
                case 0:
                    _x.trys.push([0, 56, 57, 58]);
                    tokens_1 = __values(tokens), tokens_1_1 = tokens_1.next();
                    _x.label = 1;
                case 1:
                    if (!!tokens_1_1.done) return [3, 55];
                    token = tokens_1_1.value;
                    if (!(depth === 0)) return [3, 3];
                    return [4, token];
                case 2:
                    _x.sent();
                    return [3, 54];
                case 3:
                    widgetTokenTraversers = (_v = (0, GetCommonConfigByType_1.getGenericCommonConfigByType)(token.subType)) === null || _v === void 0 ? void 0 : _v.tokenTraversers;
                    if (!widgetTokenTraversers) return [3, 12];
                    _x.label = 4;
                case 4:
                    _x.trys.push([4, 9, 10, 11]);
                    _b = (e_5 = void 0, __values(widgetTokenTraversers.getFlatFromListOfTokens(token, this.getFlatFromListOfTokens.bind(this)))), _c = _b.next();
                    _x.label = 5;
                case 5:
                    if (!!_c.done) return [3, 8];
                    visitedToken = _c.value;
                    return [4, visitedToken];
                case 6:
                    _x.sent();
                    _x.label = 7;
                case 7:
                    _c = _b.next();
                    return [3, 5];
                case 8: return [3, 11];
                case 9:
                    e_5_1 = _x.sent();
                    e_5 = { error: e_5_1 };
                    return [3, 11];
                case 10:
                    try {
                        if (_c && !_c.done && (_p = _b.return)) _p.call(_b);
                    }
                    finally { if (e_5) throw e_5.error; }
                    return [7];
                case 11: return [3, 54];
                case 12:
                    _d = token.subType;
                    switch (_d) {
                        case Types_1.WidgetBlockType.SideBySide: return [3, 13];
                        case Types_1.WidgetBlockType.Table: return [3, 30];
                    }
                    return [3, 52];
                case 13: return [4, token];
                case 14:
                    _x.sent();
                    _x.label = 15;
                case 15:
                    _x.trys.push([15, 20, 21, 22]);
                    _e = (e_6 = void 0, __values(this.getFlatFromListOfTokens(token.column1))), _f = _e.next();
                    _x.label = 16;
                case 16:
                    if (!!_f.done) return [3, 19];
                    column1Token = _f.value;
                    return [4, column1Token];
                case 17:
                    _x.sent();
                    _x.label = 18;
                case 18:
                    _f = _e.next();
                    return [3, 16];
                case 19: return [3, 22];
                case 20:
                    e_6_1 = _x.sent();
                    e_6 = { error: e_6_1 };
                    return [3, 22];
                case 21:
                    try {
                        if (_f && !_f.done && (_q = _e.return)) _q.call(_e);
                    }
                    finally { if (e_6) throw e_6.error; }
                    return [7];
                case 22:
                    _x.trys.push([22, 27, 28, 29]);
                    _g = (e_7 = void 0, __values(this.getFlatFromListOfTokens(token.column2))), _h = _g.next();
                    _x.label = 23;
                case 23:
                    if (!!_h.done) return [3, 26];
                    column2Token = _h.value;
                    return [4, column2Token];
                case 24:
                    _x.sent();
                    _x.label = 25;
                case 25:
                    _h = _g.next();
                    return [3, 23];
                case 26: return [3, 29];
                case 27:
                    e_7_1 = _x.sent();
                    e_7 = { error: e_7_1 };
                    return [3, 29];
                case 28:
                    try {
                        if (_h && !_h.done && (_r = _g.return)) _r.call(_g);
                    }
                    finally { if (e_7) throw e_7.error; }
                    return [7];
                case 29: return [3, 54];
                case 30: return [4, token];
                case 31:
                    _x.sent();
                    _x.label = 32;
                case 32:
                    _x.trys.push([32, 49, 50, 51]);
                    _j = (e_10 = void 0, __values(token.cells)), _k = _j.next();
                    _x.label = 33;
                case 33:
                    if (!!_k.done) return [3, 48];
                    row = _k.value;
                    _x.label = 34;
                case 34:
                    _x.trys.push([34, 45, 46, 47]);
                    row_1 = (e_9 = void 0, __values(row)), row_1_1 = row_1.next();
                    _x.label = 35;
                case 35:
                    if (!!row_1_1.done) return [3, 44];
                    cell = row_1_1.value;
                    _x.label = 36;
                case 36:
                    _x.trys.push([36, 41, 42, 43]);
                    _l = (e_8 = void 0, __values(this.getFlatFromListOfTokens(cell))), _m = _l.next();
                    _x.label = 37;
                case 37:
                    if (!!_m.done) return [3, 40];
                    cellToken = _m.value;
                    return [4, cellToken];
                case 38:
                    _x.sent();
                    _x.label = 39;
                case 39:
                    _m = _l.next();
                    return [3, 37];
                case 40: return [3, 43];
                case 41:
                    e_8_1 = _x.sent();
                    e_8 = { error: e_8_1 };
                    return [3, 43];
                case 42:
                    try {
                        if (_m && !_m.done && (_u = _l.return)) _u.call(_l);
                    }
                    finally { if (e_8) throw e_8.error; }
                    return [7];
                case 43:
                    row_1_1 = row_1.next();
                    return [3, 35];
                case 44: return [3, 47];
                case 45:
                    e_9_1 = _x.sent();
                    e_9 = { error: e_9_1 };
                    return [3, 47];
                case 46:
                    try {
                        if (row_1_1 && !row_1_1.done && (_t = row_1.return)) _t.call(row_1);
                    }
                    finally { if (e_9) throw e_9.error; }
                    return [7];
                case 47:
                    _k = _j.next();
                    return [3, 33];
                case 48: return [3, 51];
                case 49:
                    e_10_1 = _x.sent();
                    e_10 = { error: e_10_1 };
                    return [3, 51];
                case 50:
                    try {
                        if (_k && !_k.done && (_s = _j.return)) _s.call(_j);
                    }
                    finally { if (e_10) throw e_10.error; }
                    return [7];
                case 51: return [3, 54];
                case 52: return [4, token];
                case 53:
                    _x.sent();
                    return [3, 54];
                case 54:
                    tokens_1_1 = tokens_1.next();
                    return [3, 1];
                case 55: return [3, 58];
                case 56:
                    e_11_1 = _x.sent();
                    e_11 = { error: e_11_1 };
                    return [3, 58];
                case 57:
                    try {
                        if (tokens_1_1 && !tokens_1_1.done && (_o = tokens_1.return)) _o.call(tokens_1);
                    }
                    finally { if (e_11) throw e_11.error; }
                    return [7];
                case 58: return [2];
            }
        });
    };
    return BlockTokenTraverser;
}());
exports.BlockTokenTraverser = BlockTokenTraverser;
