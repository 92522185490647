"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeading1Token = exports.Heading1Config = void 0;
exports.Heading1Config = {
    type: "heading1",
    tokenType: {},
    publicRender: function (_a) {
        var attributes = _a.attributes, innerHtml = _a.innerHtml;
        if (innerHtml === "" || /^<span[^>]*><\/span>$/.test(innerHtml)) {
            innerHtml = "<br />";
        }
        return "<h1 class=\"".concat(attributes.className, "\">").concat(innerHtml, "</h1>");
    },
    traits: {
        spacingShim: {
            className: "h1-widget__spacing-shim",
        },
    },
};
var createHeading1Token = function () { return ({
    type: "block",
    subType: "heading1",
}); };
exports.createHeading1Token = createHeading1Token;
