"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isResponseError = exports.getResponseError = exports.getErrorMessage = exports.isAxiosError = void 0;
var ErrorCodes_1 = require("@CommonFrontendBackend/ErrorCodes/ErrorCodes");
var isAxiosError = function (error) {
    return !!(error === null || error === void 0 ? void 0 : error.isAxiosError);
};
exports.isAxiosError = isAxiosError;
var getErrorMessage = function (error) {
    var _a;
    var responseErrorMessage = getResponseErrorMessage((_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
    if (isResponseError(error) && !!responseErrorMessage) {
        return responseErrorMessage;
    }
    else {
        return error.message;
    }
};
exports.getErrorMessage = getErrorMessage;
var getResponseError = function (error) {
    var _a, _b;
    return (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error;
};
exports.getResponseError = getResponseError;
var isResponseError = function (error) {
    return !!error.response;
};
exports.isResponseError = isResponseError;
var getResponseErrorMessage = function (data) {
    var _a, _b, _c;
    var msg = (_a = data === null || data === void 0 ? void 0 : data.error) === null || _a === void 0 ? void 0 : _a.message;
    var errorCodeMessage = (0, ErrorCodes_1.getUserFacingMessage)((_b = data === null || data === void 0 ? void 0 : data.additionalInfo) === null || _b === void 0 ? void 0 : _b.errorCode);
    return (_c = msg !== null && msg !== void 0 ? msg : errorCodeMessage) !== null && _c !== void 0 ? _c : data === null || data === void 0 ? void 0 : data.message;
};
