"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColorValues = void 0;
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var pages_common_1 = require("@qwilr/pages-common");
function getColorValues(_a, rawStyleObject, brandColors) {
    var _b, _c;
    var name = _a.name, defaultValue = _a.defaultValue, variable = _a.variable, contrastFriendlyForeground = _a.contrastFriendlyForeground;
    var style = {};
    var styleColor = (_b = rawStyleObject === null || rawStyleObject === void 0 ? void 0 : rawStyleObject[name]) !== null && _b !== void 0 ? _b : defaultValue;
    var mainColor = (_c = (0, GetBlockStyle_1.sanitizeColor)(styleColor, brandColors)) === null || _c === void 0 ? void 0 : _c.value;
    style[variable] = mainColor;
    if (contrastFriendlyForeground) {
        var shouldUseDarkText = contrastFriendlyForeground.shouldUseDarkText, simpleUiGoldilocks = contrastFriendlyForeground.simpleUiGoldilocks;
        if (shouldUseDarkText) {
            var useDarkText = (0, ColorUtils_1.shouldUseDarkText)(mainColor);
            style[shouldUseDarkText.variable] = useDarkText ? shouldUseDarkText.darkColor : shouldUseDarkText.lightColor;
        }
        if (simpleUiGoldilocks) {
            style[simpleUiGoldilocks.variable] = (0, pages_common_1.goldilocks)(mainColor, { mode: "ui" });
        }
    }
    return style;
}
exports.getColorValues = getColorValues;
