"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserFacingMessage = exports.TMSErrorCode = exports.ServerErrorCode = exports.ReportType = exports.InviteErrorCodes = exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["COLLAB_HAVE_OLD_VERSION"] = "Block version in db is ahead of expected version";
    ErrorCodes["PROJECT_ACCEPTED"] = "Project has been accepted";
    ErrorCodes["PROJECT_NOT_EDITABLE"] = "The project is not editable";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
var InviteErrorCodes;
(function (InviteErrorCodes) {
    InviteErrorCodes["NO_ACCESS_OUTSIDE_DOMAIN"] = "You don't have access to invite users outside your team domain";
    InviteErrorCodes["BAD_INVITE_LIST"] = "Invalid list of invites";
    InviteErrorCodes["TOO_MANY_INVITES"] = "Too many users invited. Please invite fewer users at a time";
    InviteErrorCodes["INVALID_EMAIL_PARTIAL"] = "Invalid email address:";
    InviteErrorCodes["INSUFFICIENT_PAGE_PERMISSIONS"] = "You don't have permission to invite users to the page";
})(InviteErrorCodes || (exports.InviteErrorCodes = InviteErrorCodes = {}));
var ReportType;
(function (ReportType) {
    ReportType["Warn"] = "warn";
    ReportType["Error"] = "error";
    ReportType["None"] = "none";
})(ReportType || (exports.ReportType = ReportType = {}));
var ServerErrorCode;
(function (ServerErrorCode) {
    ServerErrorCode[ServerErrorCode["FolderBelongsToAnotherAccount"] = 10000] = "FolderBelongsToAnotherAccount";
    ServerErrorCode[ServerErrorCode["PageBelongsToAnotherAccount"] = 10001] = "PageBelongsToAnotherAccount";
    ServerErrorCode[ServerErrorCode["TokenAlreadyExists"] = 10002] = "TokenAlreadyExists";
    ServerErrorCode[ServerErrorCode["TokenQuotaExceeded"] = 10003] = "TokenQuotaExceeded";
    ServerErrorCode[ServerErrorCode["EmailAlreadyExists"] = 10004] = "EmailAlreadyExists";
    ServerErrorCode[ServerErrorCode["CannotManuallyAcceptPage"] = 10005] = "CannotManuallyAcceptPage";
    ServerErrorCode[ServerErrorCode["IntegrationObjectNotFound"] = 10006] = "IntegrationObjectNotFound";
    ServerErrorCode[ServerErrorCode["AuthenticationMethodNotAllowed"] = 10007] = "AuthenticationMethodNotAllowed";
    ServerErrorCode[ServerErrorCode["ExternalUserIDAlreadyLinked"] = 10008] = "ExternalUserIDAlreadyLinked";
    ServerErrorCode[ServerErrorCode["FolderDoesNotExist"] = 10009] = "FolderDoesNotExist";
    ServerErrorCode[ServerErrorCode["ProjectDoesNotExist"] = 10010] = "ProjectDoesNotExist";
    ServerErrorCode[ServerErrorCode["AutomationAssociationsLimitReached"] = 10011] = "AutomationAssociationsLimitReached";
})(ServerErrorCode || (exports.ServerErrorCode = ServerErrorCode = {}));
var TMSErrorCode;
(function (TMSErrorCode) {
    TMSErrorCode["OAUTH_SCOPE_MISMATCH"] = "OAUTH_SCOPE_MISMATCH";
    TMSErrorCode["OAUTH_REFRESH_TOKEN_EXPIRED"] = "OAUTH_REFRESH_TOKEN_EXPIRED";
    TMSErrorCode["EXTERNAL_USERID_ALREADY_LINKED"] = "EXTERNAL_USERID_ALREADY_LINKED";
})(TMSErrorCode || (exports.TMSErrorCode = TMSErrorCode = {}));
var ErrorCodeToMessage = (_a = {},
    _a[ServerErrorCode.CannotManuallyAcceptPage] = "Cannot manually accept a page that has an Accept or Quote block",
    _a);
var getUserFacingMessage = function (errorCode) {
    return ErrorCodeToMessage[errorCode];
};
exports.getUserFacingMessage = getUserFacingMessage;
