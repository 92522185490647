"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCrmTemplate = exports.isTemplate = exports.isActionAllowed = exports.pageStatusDisplayText = void 0;
var lodash_1 = require("lodash");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var Types_2 = require("@CommonFrontendBackend/Project/Types");
var blacklistedActions = (_a = {},
    _a[Types_1.ProjectStatus.Draft] = [Types_1.ProjectActions.PublicPageView],
    _a[Types_1.ProjectStatus.Accepted] = [Types_1.ProjectActions.Delete, Types_1.ProjectActions.Edit],
    _a[Types_1.ProjectStatus.Declined] = [Types_1.ProjectActions.Edit, Types_1.ProjectActions.PublicPageView],
    _a);
var pageStatusDisplayText = function (status) {
    return status === Types_1.ProjectStatus.Draft ? "Draft" : (0, lodash_1.startCase)(status);
};
exports.pageStatusDisplayText = pageStatusDisplayText;
var isActionAllowed = function (status, action) {
    return blacklistedActions[status] == null || !blacklistedActions[status].includes(action);
};
exports.isActionAllowed = isActionAllowed;
var isTemplate = function (project) {
    return Array.isArray(project.tags) && project.tags.includes(Types_2.SpecialProjectTag.Template);
};
exports.isTemplate = isTemplate;
var isCrmTemplate = function (project) {
    var _a;
    return (0, exports.isTemplate)(project) && ((_a = project.crmInfo) === null || _a === void 0 ? void 0 : _a.connected);
};
exports.isCrmTemplate = isCrmTemplate;
