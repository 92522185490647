"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorCodes_1 = require("@CommonFrontendBackend/ErrorCodes/ErrorCodes");
var InternalServerError_1 = require("Errors/InternalServerError");
var OAUTH_WINDOW_POLL_TIMEOUT_MS = 500;
var OAuthWindow = (function () {
    function OAuthWindow() {
    }
    OAuthWindow.show = function (url, _a) {
        var _b = _a === void 0 ? { width: 900, height: 500 } : _a, width = _b.width, height = _b.height;
        var left = (window.screen.width - width) / 2;
        var top = (window.screen.height - height) / 2;
        var authWindow = window.open(url, "_blank", "top=".concat(top, ",left=").concat(left, ",width=").concat(width, ",height=").concat(height));
        if (!authWindow) {
            throw new Error("Unable to open authorization window");
        }
        return new Promise(function (resolve, reject) {
            var intervalId = window.setInterval(function () {
                if (authWindow.closed) {
                    window.clearInterval(intervalId);
                    reject("The authorization process was cancelled.");
                }
                try {
                    if (authWindow.QWILR_SUCCESS) {
                        window.clearInterval(intervalId);
                        authWindow.close();
                        resolve();
                    }
                    else if (authWindow.QWILR_FAILURE) {
                        window.clearInterval(intervalId);
                        authWindow.close();
                        reject(new Error("The authorization process could not be completed. Please try again."));
                    }
                    else if (authWindow.QWILR_REQUIRE_CONFIRMATION) {
                        window.clearInterval(intervalId);
                        authWindow.close();
                        reject(new InternalServerError_1.InternalServerError("This CRM user is already linked to a Qwilr user.", ErrorCodes_1.ReportType.Error, {
                            errorCode: ErrorCodes_1.ServerErrorCode.ExternalUserIDAlreadyLinked,
                        }));
                    }
                }
                catch (error) {
                }
            }, OAUTH_WINDOW_POLL_TIMEOUT_MS);
        });
    };
    return OAuthWindow;
}());
exports.default = OAuthWindow;
