"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkStyle = void 0;
var MarkStyle;
(function (MarkStyle) {
    MarkStyle["Any"] = "any";
    MarkStyle["Bold"] = "bold";
    MarkStyle["Italic"] = "italic";
    MarkStyle["Strikethrough"] = "strikethrough";
    MarkStyle["Style"] = "style";
    MarkStyle["Comment"] = "comment";
})(MarkStyle || (exports.MarkStyle = MarkStyle = {}));
