import "../CommonFrontend/AcceptFlow/ESign/ESignV3.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ESign/ESignV3.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW227bOBB9z1dwGywQb0uv5EuTKECxQIv9gO4HGBQ5sphQJJccJXIX+feFKNsSZSV12oc+2RBnhjNnzpzhfJNWT7L0u5T8d0GIkN4qtstIoaC5uyDhlwrpgKM0OiPcqLrS7cmW2Yw8MndFqbeMS72lTdOozSbN1fX9fZqaWWtWMbeVmuYG0VTfd3i+mB8yWoSMXvUfOKvYeTVdzn3tURY7yo1G0JiRNhLQHPAJQEcR1iFCzvjD1plaC8qNMi4jT6VEaGPlxglw1DEha3/IrPv4NXwLtzgzSBJmvWNGUtsQb5QU5PImZYt8sUqiDD52GZxaFwWH9TKyvQ62JchtiRnhTPGrLqF9Dv/Ib0CrPpNmRt6TNKRjmRABTCjwpEG+d5HB+gRSpuRWU4lQ+Yx4dIC8PAfqI4CH1p7CIUYNmSPLfSg0kC+ZOt5spBaSMzTuhQZeJsnN+jqfdkaWUyU9Uqk1uAjUNEl+nyyMg0ZwUbyb4LlH9pT1A1TLmLe3naPxsps3B4qhfAyEY94CR+oYSpORxXx5S/4kaeTOgntsuJynyxNDMbqH5d6ouiO21B5wDC+8XtGA5DrmydZJMYTtlCkRg0DHXS/CvU9SYNm34DATyTk0ukkmpjW5e6WaiPQk6YxNjUpqyIg2upv/I7P6b4XRSAtWSbXLyBdWSaM/EF47v29hOPfyG2RkubJhfg6sXKwWn5e3ce1ZYXjtBzJw5PDqer1cC85H9plVjENplNiT9ycSEgVcQ05+k5U1DpnGgEKLEe6yEZ22sdx2cAzOy+/TLe7BQKoeItFc2IYI5kuIVPMn1BihQRoo2E/ymxbhi3owYnb/eWJ1juodQCen2p8mt7d/f76b1rf2sODLeEHck0/kj6mNGo/5w9S4xQo4sFbB+siJbqiMbOuk8Aga/QQX9I84mdgpZp+dXvY/TIk9QmhsW7Nt7ibXMDqmvWUOuskITe2sB6n9+4Ydbsmcl8AfctNQbiprNGjcbLiS/IEWEpSINTg5xzs3zaDr5ztRKj31oIAjiDds0r3sKGhCl/rnRUBzHUtMULEvI4lp1ak93XNiFP0TsafC9o4zJXMHVMGW8d27D8Qz3abvZDGKPhC89KNtRqetwtMj3eerk8u7ERpqhkfmMAozdskU80h5KeMG9hP4kndy/JfOGedgkRbGVRS83GqGtQMyD6lIbWtsXz62xqFcRNvutaW5OBLz8GpUxtESWJvocTwgei9K3e3D5MWcD/+WZ2WvWA6q7+2+R+OBOgeT45tl/NyZnpwzQvYx178G378qEJIRzx2AJkwLclWxhu6FerVObDPbZyZ2mTZ4dWmd1DgboBHOCUkyUmsBhdQQHmbPr3oVL3gRkp7Eef4fujlpgU4OAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = _7a468({defaultClassName:'_1mwihsy0',variantClassNames:{visualMode:{simple:'_1mwihsy1',standard:'_1mwihsy2'},isPrintMode:{true:'_1mwihsy3'}},defaultVariants:{},compoundVariants:[]});
export var header = '_1mwihsy4';
export var card = _7a468({defaultClassName:'_1mwihsy5',variantClassNames:{invalid:{true:'_1mwihsy6'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1mwihsy7';
export var actions = '_1mwihsy8';
export var tabPanel = _7a468({defaultClassName:'_1mwihsy9',variantClassNames:{mode:{type:'_1mwihsya',draw:'_1mwihsyb',upload:'_1mwihsyc'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = '_1mwihsye _1mwihsyd';
export var input = '_1mwihsyf';
export var uploadWrapper = _7a468({defaultClassName:'_1mwihsyd',variantClassNames:{hasUploaded:{true:'_1mwihsyg'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1mwihsyh',variantClassNames:{draggedOver:{true:'_1mwihsyi'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1mwihsyj';
export var canvas = _7a468({defaultClassName:'_1mwihsyk _1mwihsyd',variantClassNames:{mode:{type:'_1mwihsyl',draw:'_1mwihsym',upload:'_1mwihsyn'},hasUploaded:{true:'_1mwihsyo'}},defaultVariants:{},compoundVariants:[]});
export var disclaimer = _7a468({defaultClassName:'_1mwihsyp',variantClassNames:{invalid:{true:'_1mwihsyq'}},defaultVariants:{},compoundVariants:[]});
export var disclaimerText = '_1mwihsyr';