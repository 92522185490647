import "../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81VQW7bMBC85xXbQwCrDQPJceJWvbRf6AcMiqSstSmSIClHRuG/F6QkS3KcIJcEhS8muTu7M7NY3RePa18US/h7A1BQtt9a3Siew4HaBSFMS22Ja2xJmdhsskKud7tsm/wM0dpyYYmlHBs3JHSXf+Ldi7ys7BNb4irK9fOQ1Z2IkOJAPWr1MKbwmPKM3Fc5ZGl6G46VwG3lh/Pp5r5j8RBZDI+MSrYIEUDgaWnaCMTRGUmPOZRStJPUVUwNlzlkIVAfhC2lfibHHGjj9XDnmNVSkkJU9IDa5sC08hTVBOsxYg0AOVTIuZgGPMUAdFpGtnn/V4QaqJzwZ10MZai2pHWjIBh5GO2wyy2xFTxc7RrnsTwSJ2SZAxPKC3uFMkBNWzIIulqlpp0oPBVt8VYT8BWWSdLB2S2qQaUzyXU3Ut2QFNp7XeeQmRaclshn89UFndGPydTjYN0E9vvM44sB+BEfeyqBZjBHKH/J+rEnPQ5818l5iiNamkyh8yo42pMaBziNvwfTQselxnaBCpzdFncXaLBMb+/AW6qcoVYon8AXrI22noYWz6VoLDI6TAunZdONh+14z52RozEyaue16a0M6sG3i/iJkbtkSnIJZyHv95J40frYyxVxsnnz1+YdIACQ8UFIicahm1csta3JYbnZhD+To2uKGj0pGu+1uthPr9n1P7T6eYPysiNWCbYPVTk6JinWwv5mTBgv+OSR6dpoJZTfbMKRoAtLQ7AQ9T6dP7SFq1Ee2b7b0Sjlm/5+ZG+f4O2vWnCksKhRDftqnaamTWLV2RfkPVt7thvGpf3Kh+bFJjmNNX/0NSeLdJX2i/R0c/oH9fv5L0oIAAA=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var modalHeaderHeight = '62px';
export var modalInset = 'var(--spacing-xs__1bl7jj11i)';
export var navButtonColor = 'var(--b57tbb0)';
export var navButtonTextColor = 'var(--b57tbb1)';
export var container = 'b57tbb2';
export var body = 'b57tbb3';
export var content = 'b57tbb4';
export var headerTitle = 'b57tbb5';
export var wrapper = 'b57tbb6';
export var header = 'b57tbb7';
export var spinner = 'b57tbb8';
export var formNavButton = 'b57tbb9';
export var notification = 'b57tbba';