"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createListItemToken = exports.ListItemConfig = void 0;
var List_1 = require("@CommonFrontend/ContentTokens/Models/List");
var ListItem_1 = require("@CommonFrontend/ContentTokens/TokenRenderers/ListItem");
exports.ListItemConfig = {
    type: "listItem",
    tokenType: {},
    publicRender: function (_a) {
        var token = _a.token, innerHtml = _a.innerHtml, index = _a.index, tokens = _a.tokens;
        return (0, ListItem_1.createHtmlFromListItem)(token, { innerHtml: innerHtml, index: index, tokens: tokens });
    },
    traits: {
        spacingShim: {
            className: "listitem-widget__spacing-shim",
        },
    },
};
var createListItemToken = function (_a) {
    var _b = _a === void 0 ? {} : _a, level = _b.level, type = _b.type;
    return ({
        type: "block",
        subType: "listItem",
        level: level !== null && level !== void 0 ? level : 1,
        listType: type !== null && type !== void 0 ? type : List_1.ListItemType.Bullet,
    });
};
exports.createListItemToken = createListItemToken;
