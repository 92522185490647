import "../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUwY7bIBC95yvooVJzILLTdJMlt/7CHnqMxjC2ZxcDArxxUvXfK+Ou7STbKm3lA2KYN7x5fsPK7WQJNmPfF4w5GyiSNYJ51BDpFfeLH4vVkJKnFGm19YK9gv/EedpwktY8tVJiCIdDXujt83MelzPkOiGPpGIt2C5z3X7BWI1U1XHaF9Yr9NyDojYIlmfZxz6sKDgNJ8EqT6oPOA0SOUVsgmASTUSf4CBfKm9bo/g7FEPrS5A40quWw5UdDzUoexSMTMDIsvTlrrtAO08N+NPXxHCskacaoKkyPKAuJzZj459T46U1kQc6o2D5znXsAzXO+ggm7t9Oj7/EeMiyi+Ox0iZVGsUoNSbR+pUr8iiH3yatbhszw31JuArcmx7BgSRT8a7r9NSKnf+uh99hZgA9B2z/4ZLd317yeOVQKILVbcReBo1lFCybZcMfsv2g9Tx9M8q1ithFTsa18XBIS6p05c5scuzFhhc2RtsItnYdC1aTujBSjaDIVGOHmCzkQPVRTkaTwfuJCZFmobZaoU8sba9hPN1VQkOB+safaRhvoNvRGf/NYnsHi0GyPvBEZ+QpZ+aLdbu8GZwJ8i1FeIOK2mbCnBLmnaehZzOmbZZX05m4Rg8mlNY3gklwFEHTuX8ZfwJbMgbMPAUAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW227jNhB9z1ewCArEbelKvsSxAhQFdtEP6D700aDIkc2EIrXkKLFT5N8LUrIkylpv0H0SMJwZzZw5c8j5Lj2ltv5apeTfG0Jyxp/31tRaUG6UsRl5PUiER39krABLLROydhl5YfaO0sb4d7BRbjRao3a7NFebp6c0hVkfmJG0OhJnlBTk9iFli3yxSh5v3m/m5woWTQWX3p836Xa5jXyXwfcAcn/AjHCm+F1TUFvDF/kGtOwrOc7IryQN5VRMCKn3VEGB5zZcxbi3HV0fIoO3kK5S7JSRQsHRG5iSe00lQuky4tAC8oO3P9UOZXEKBYDGjPicQHPAVwA9ADA3iKacgkNELaZkjix3odE9qzKSTB3vdlILyRka+40B3ibJw3qTTwcjy6mSDqnUGmwEapokP082xkEj2CjfKkS2yF6AOkT1MIsC102gcRKl0RlhuTOqbggntQMct30/CrCgGMqXEMBcBRypZShNRhbz5Zb8TtIofBPCY8flPF1eOLIPFXal58Ea6JhJeyvFENhLLkUcAx3zIo+xjuHh4fBVCjz0EzyvVPIRFj4kE8t+tdVoZ0jSOJsaldSQEW10Ix8dMXtbYTTSgpVSnTLymZXS6N8Ir61rBxrOnXyDjCxXVVi/M6kXq8WnkSbwrDC8dgMV6VZgtVkv14LzkX9WKcbhYJRouf8DBYkCNpCTn2RZGYtMY0DBY4SnbEQuMazxPIlFN4mzkilj6QGYB72DuJHU847eL3wRg8wQMndUa4AenBffp3U83YGGPkdq7ssVzB0gkvMfuCYQjkgD83uJmdBf/6VCWuBNB9youtRXhWq0UL05yOrVfgfQ7aeIlSbb7V+fHqeF1x8WfLmM0hzIH+SXkKpkdi91N/94keXUIsfSPPB+Ct4d25p1NdL3SeEFNLoJLqj/E1TGQTGv9VCausbGyjgUjH2Msbmi7mguhnWZy/PDMMyIv92H63l+Q0wOSoRDP6iP8NfVtmAc+h8X4cftOG0zoo9fgdWADO0UvHLSbtjzRfCvHnjBzKr93pMuQUIGA5g/K+o3aZR+DMPg/1+DA6WMc6gwBJ+BGQqRt3c9rGYjNWw8vSG8vBTLQdF+xRd1H/Da9rVKkm7t0TLtCmNL/5arJDIl38LQFaCnY4tjuK6i4nHYXVPERR8x3PVwsbZj/awDgA4UcNztQEEJGikFLUavq4nViGOlrmqMXrTngH5clsxN5ZlOS9D1budrvryHpD6Alfh9sCJ4+4n8Eyy0BCHrXt4Wp4DLn97MiOMWQBOmBbkr2ZG2+KzWSXWctV2IU6YN3t1WVmqckdFTiZAkI7UWUEgN4X3zfjWKfyOKkPQiz/t/w48h46YMAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var signatureContainer = '_1y1ruqp0';
export var card = _7a468({defaultClassName:'_1y1ruqp1',variantClassNames:{invalid:{true:'_1y1ruqp2'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1y1ruqp3';
export var actions = '_1y1ruqp4';
export var tabPanel = _7a468({defaultClassName:'_1y1ruqp6',variantClassNames:{mode:{type:'_1y1ruqp7',draw:'_1y1ruqp8',upload:'_1y1ruqp9'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = _7a468({defaultClassName:'_1y1ruqpa',variantClassNames:{isSimpleMode:{true:'_1y1ruqpb'}},defaultVariants:{},compoundVariants:[]});
export var input = _7a468({defaultClassName:'_1y1ruqpc',variantClassNames:{isSimpleMode:{true:'_1y1ruqpd'}},defaultVariants:{},compoundVariants:[]});
export var uploadWrapper = _7a468({defaultClassName:'_1y1ruqp5',variantClassNames:{hasUploaded:{true:'_1y1ruqpe'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1y1ruqpf',variantClassNames:{draggedOver:{true:'_1y1ruqpg'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1y1ruqph';
export var canvas = _7a468({defaultClassName:'_1y1ruqpi _1y1ruqp5',variantClassNames:{mode:{type:'_1y1ruqpj',draw:'_1y1ruqpk',upload:'_1y1ruqpl'},hasUploaded:{true:'_1y1ruqpm'}},defaultVariants:{},compoundVariants:[]});
export var error = '_1y1ruqpn';
export var errorIcon = '_1y1ruqpo';
export var errorText = '_1y1ruqpp';
export var simpleModeLabel = '_1y1ruqpq';
export var optionMenuItem = _7a468({defaultClassName:'',variantClassNames:{isSimpleMode:{true:'_1y1ruqpr',false:'_1y1ruqps'}},defaultVariants:{},compoundVariants:[]});
export var label = '_1y1ruqpt';
export var select = '_1y1ruqpu';