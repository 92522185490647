"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetFolderType = exports.InlineContentAddedErrorName = exports.ContentAddedOrigin = exports.ContentAddedType = exports.ProjectCreatedCloneOrigin = exports.ProjectOpenedOrigin = exports.ProjectShareViewedOrigin = void 0;
var ProjectShareViewedOrigin;
(function (ProjectShareViewedOrigin) {
    ProjectShareViewedOrigin["Dashboard"] = "dashboard";
    ProjectShareViewedOrigin["DashboardFiltered"] = "dashboard filtered";
    ProjectShareViewedOrigin["ProjectNavBar"] = "project nav bar";
    ProjectShareViewedOrigin["SearchOptionsMenu"] = "search options menu";
    ProjectShareViewedOrigin["Playground"] = "playground";
})(ProjectShareViewedOrigin || (exports.ProjectShareViewedOrigin = ProjectShareViewedOrigin = {}));
var ProjectOpenedOrigin;
(function (ProjectOpenedOrigin) {
    ProjectOpenedOrigin["Dashboard"] = "dashboard";
    ProjectOpenedOrigin["DashboardFiltered"] = "dashboard filtered";
    ProjectOpenedOrigin["Search"] = "search";
    ProjectOpenedOrigin["SearchOptionsMenu"] = "search options menu";
    ProjectOpenedOrigin["DocConversion"] = "doc conversion";
    ProjectOpenedOrigin["OnboardingProject"] = "onboarding project";
    ProjectOpenedOrigin["CollaboratorLink"] = "collaborator link";
    ProjectOpenedOrigin["SignupWithTemplate"] = "signup with template";
    ProjectOpenedOrigin["SignupWithPageBuilderAI"] = "signup with page builder AI";
    ProjectOpenedOrigin["TemplateManagementDashboard"] = "template management dashboard";
    ProjectOpenedOrigin["TemplatesGallery"] = "templates gallery";
    ProjectOpenedOrigin["OnboardingExplorer"] = "onboarding explorer";
})(ProjectOpenedOrigin || (exports.ProjectOpenedOrigin = ProjectOpenedOrigin = {}));
var ProjectCreatedCloneOrigin;
(function (ProjectCreatedCloneOrigin) {
    ProjectCreatedCloneOrigin["Dashboard"] = "dashboard";
    ProjectCreatedCloneOrigin["DashboardFiltered"] = "dashboard filtered";
    ProjectCreatedCloneOrigin["SearchOptionsMenu"] = "search options menu";
    ProjectCreatedCloneOrigin["EditorProjectOptionsMenu"] = "editor project options menu";
})(ProjectCreatedCloneOrigin || (exports.ProjectCreatedCloneOrigin = ProjectCreatedCloneOrigin = {}));
var ContentAddedType;
(function (ContentAddedType) {
    ContentAddedType["Video"] = "video";
    ContentAddedType["Table"] = "table";
    ContentAddedType["GoogleMaps"] = "googlemaps";
    ContentAddedType["Iframe"] = "iframe";
    ContentAddedType["SideBySide"] = "sidebyside";
    ContentAddedType["Image"] = "image";
    ContentAddedType["Button"] = "button";
    ContentAddedType["Accordion"] = "accordion";
    ContentAddedType["ROICalculator"] = "roi";
    ContentAddedType["Chevron"] = "chevron";
    ContentAddedType["Box"] = "box";
    ContentAddedType["HorizontalRule"] = "horizontalrule";
    ContentAddedType["QuoteSummary"] = "quotesummary";
})(ContentAddedType || (exports.ContentAddedType = ContentAddedType = {}));
var ContentAddedOrigin;
(function (ContentAddedOrigin) {
    ContentAddedOrigin["Keyboard"] = "keyboard";
    ContentAddedOrigin["Click"] = "click";
    ContentAddedOrigin["Paste"] = "paste";
    ContentAddedOrigin["Drag"] = "drag";
})(ContentAddedOrigin || (exports.ContentAddedOrigin = ContentAddedOrigin = {}));
var InlineContentAddedErrorName;
(function (InlineContentAddedErrorName) {
    InlineContentAddedErrorName["InvalidEditor"] = "invalid editor";
    InlineContentAddedErrorName["InvalidContainer"] = "invalid container";
    InlineContentAddedErrorName["LockedBlock"] = "locked block";
    InlineContentAddedErrorName["MissingLocation"] = "missing location";
    InlineContentAddedErrorName["MissingEditor"] = "missing editor";
})(InlineContentAddedErrorName || (exports.InlineContentAddedErrorName = InlineContentAddedErrorName = {}));
var AssetFolderType;
(function (AssetFolderType) {
    AssetFolderType["Unsplash"] = "unsplash";
    AssetFolderType["Uploads"] = "uploads";
    AssetFolderType["SavedBlocks"] = "saved blocks";
    AssetFolderType["AccountFolder"] = "account folder";
    AssetFolderType["RecentlyUsed"] = "recently used";
})(AssetFolderType || (exports.AssetFolderType = AssetFolderType = {}));
