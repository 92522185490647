"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockBorderZIndex = exports.blockMenuZIndex = exports.promptZIndex = exports.helpZIndex = exports.contentSidebarZIndex = exports.navBarZIndex = exports.modalZIndex = exports.overlayStackZIndex = exports.angularTooltipZIndex = void 0;
exports.angularTooltipZIndex = 800;
exports.overlayStackZIndex = 890;
exports.modalZIndex = 1051;
exports.navBarZIndex = 700;
exports.contentSidebarZIndex = exports.overlayStackZIndex + 1;
exports.helpZIndex = 1052;
exports.promptZIndex = 1053;
exports.blockMenuZIndex = 500;
exports.blockBorderZIndex = 10;
