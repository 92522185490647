"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelemetryEventName = void 0;
var TelemetryEventName;
(function (TelemetryEventName) {
    TelemetryEventName["ViewBlueprintChildren"] = "Project Blueprint Children Viewed";
    TelemetryEventName["DashboardSorted"] = "Project Dashboard Sorted";
    TelemetryEventName["DashboardFiltered"] = "Projects Dashboard Filtered";
    TelemetryEventName["DashboardRememberedFolder"] = "Select Remembered Folder";
    TelemetryEventName["DashboardCreateNewClicked"] = "Dashboard Create New Button Clicked";
    TelemetryEventName["DashboardCreateNewResourceClicked"] = "Dashboard Create New Resource Clicked";
    TelemetryEventName["DashboardViewed"] = "Dashboard Viewed";
    TelemetryEventName["LoginOnlyPageViewed"] = "LoginOnly Page Viewed";
    TelemetryEventName["ProjectShareViewed"] = "Project Share Viewed";
    TelemetryEventName["ProjectCollaborateViewed"] = "Project Collaborate Viewed";
    TelemetryEventName["NavigationTabClicked"] = "Navigation Tab Clicked";
    TelemetryEventName["ProjectOpened"] = "Project Opened";
    TelemetryEventName["ProjectOpenIntent"] = "Project Open Intent";
    TelemetryEventName["ProjectOwnerChanged"] = "Project Owner Changed";
    TelemetryEventName["ProjectTagsViewed"] = "Project Tags Viewed";
    TelemetryEventName["PageStateChanged"] = "Page State Changed";
    TelemetryEventName["PageExpiryIncreased"] = "Page Expiry Increased";
    TelemetryEventName["PageExpiryDecreased"] = "Page Expiry Decreased";
    TelemetryEventName["PageExpiryChanged"] = "Page Expiry Changed";
    TelemetryEventName["PageAccessSettingChanged"] = "Page Access Setting Changed";
    TelemetryEventName["OnboardingUserDataSubmitted"] = "Account Data Submitted";
    TelemetryEventName["OnboardingSkipped"] = "Onboarding Skipped";
    TelemetryEventName["OnboardingStepReached"] = "Onboarding Step Reached";
    TelemetryEventName["RecipientCollaboratorPageViewed"] = "Recipient Collaborator Page Viewed";
    TelemetryEventName["DraftPublicPageViewAttempt"] = "Draft Public Page View Attempt";
    TelemetryEventName["CollaboratorLinkCopied"] = "Collaborator Link Copied";
    TelemetryEventName["PublicLinkCopied"] = "Public Link Copied";
    TelemetryEventName["QwilrLogoOpened"] = "Qwilr Logo Opened";
    TelemetryEventName["CollaboratorCommentWidgetClicked"] = "Collaborator Comment Widget Clicked";
    TelemetryEventName["InviteRequestSubmitted"] = "Invite Request Submitted";
    TelemetryEventName["UserInvited"] = "User Invited";
    TelemetryEventName["InviteRequestDeleted"] = "Invite Request Deleted";
    TelemetryEventName["UpgradeStepTaken"] = "Upgrade Step Taken";
})(TelemetryEventName || (exports.TelemetryEventName = TelemetryEventName = {}));
