import "../CommonFrontend/ContentTokens/SharedWidgetComponents/QuoteSummary/QuoteSummaryWidget.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/SharedWidgetComponents/QuoteSummary/QuoteSummaryWidget.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71XUXPiNhB+51fspJMxzJ2MjSEBM3Ta3vStfe5jRraEUZAlnyxj0w7/vSMZEtuYhCOZexG2rP129e2n1eI++YLiYrHw4b8BQMmI3oTge979cgAQSUWoQrHkHGc5DeH0ZD5qHHGKON7LQoewZhUly8Fh4L4ghkLqYUjTTO9H8AXctqsUq4QJpGUWwg6rIUJ5hmMmElRV/OnJj/jj87Pvi1EH1OJZBMLyjON9CEIK2lo1aXqIpNYy7TrJX108t10EHzGetiNLFCOGK/OLNE0zjjU1fBapyENQNKNYD6dfwV8rCxRJsv8lU0xouIRZ865k+QZwIXKqW4HNLEiGCWEiOWO8sSE2Aq+Re5sfP6sgl5yRo10suVRoQ7EBezGlo4bdibjrTRvBPthgNa00wpwlIoRcY9Xe0OPZGiraApzbFZahmpgQfBjD7BLP8+YRmNUnoGV9zuqiX4h96Iu+LMaU8+Wr0xjzeDjz7mEMQZsRfH48G1+jW1QXvK266DNUF9sa4MZSaCYKSkYWtCORyS0SIX1IN4lt/aMnY0eVZjHmJ91FOKecdUpQcknCL66QYslGX19aNu8KnvULftpJsVZnWelAfIBWrdw846whpGcLKc3+9D40FJpVl0N4/rTMbj/rTus7H9uzatHwzH9MUw3L9IrSJ/qVAFBKRVCpcBZCpCjeIjPRMpWfRm5244XyIaffr2BH3c5O/uaeGl1JOgLv592hQVYBkUXE6RW2ayk0Kmmztpipf+wMiiQnLwaB12ZXX8FucTu7u1dTQmOpsGZShGBKJ9IbJYtk01rvNS+jl8cSCv4rZ2FE11JRC2mqCBU6hLu7ZfPOYsJiR1zG28YtO5lmlXndHEk6va85rULwwANcaGkrtcxZHaSiHGu2o331P2WEcPslwvE2UbIQBLEUJzSEQvHhHcEah3ZinO+SL1XKl4Vez7/eB9/yXQJVykW+cjZaZ+F4XJalWwauVMl44nmesXDqyFfOZOoco66fd4yWf8hq5ZigJ1OYTJ374M/74FuG9QbWjPOVo5JoGMzAD8CfBCPHziJVcLpy6I4KSYgDZOX87S/cR+8R5mb8y/fAn7tTf8rRzEzUI/hmCtnRLpm5s/kDn7uTRYDsWK+ox3+dcR2M2YJ58n6/s3rrVtKr86wVFvlaqjSEPMacDj13ZjXf/C+BHkw2D4PfUkoYhjxWlArAgsAwZQIdNfDoeVlVdyOdPvtSz/Ne1wNwaILNj2DtdrK/oeyYLvrjOPWKl7rFDkr0mbthR7DL0b/fVHzgBuhEs+3l9jA4/A9l8wRizg4AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = '_1neau990';
export var table = '_1neau991';
export var quoteHeading = '_1neau992';
export var sectionDescription = '_1neau993';
export var colHeadingRow = '_1neau994';
export var colHeading = _7a468({defaultClassName:'_1neau995',variantClassNames:{align:{left:'_1neau996',right:'_1neau997'},isFirstColumn:{true:'_1neau998',false:'_1neau999'}},defaultVariants:{align:'left',isFirstColumn:false},compoundVariants:[]});
export var section = '_1neau99a';
export var lineItem = _7a468({defaultClassName:'_1neau99b',variantClassNames:{isLastRow:{true:'_1neau99c'}},defaultVariants:{isLastRow:false},compoundVariants:[]});
export var quoteTotal = '_1neau99d';
export var totalLine = '_1neau99e';
export var cell = _7a468({defaultClassName:'_1neau99f',variantClassNames:{align:{left:'_1neau99g',right:'_1neau99h'},isFirstColumn:{true:'_1neau99i',false:'_1neau99j'}},defaultVariants:{align:'left',isFirstColumn:false},compoundVariants:[]});
export var total = '_1neau99k';
export var totalCell = _7a468({defaultClassName:'_1neau99l',variantClassNames:{align:{left:'_1neau99m',right:'_1neau99n'},isLastRow:{true:'_1neau99o'}},defaultVariants:{align:'left',isLastRow:false},compoundVariants:[]});
export var subTotalCell = _7a468({defaultClassName:'_1neau99p',variantClassNames:{align:{left:'_1neau99q',right:'_1neau99r'}},defaultVariants:{align:'left'},compoundVariants:[]});
export var grandTotalCell = _7a468({defaultClassName:'_1neau99s',variantClassNames:{align:{left:'_1neau99t',right:'_1neau99u'}},defaultVariants:{align:'left'},compoundVariants:[]});
export var preDiscountValue = '_1neau99v';
export var lineItemFeatureList = '_1neau99w';
export var discountText = '_1neau99x';
export var grandTotalValue = '_1neau99y';