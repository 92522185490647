"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isQuoteStyle = exports.isAcceptStyle = exports.isSplashStyle = exports.isSourceStyle = exports.AccordionWidgetButtonAlignment = exports.AccordionWidgetButtonStyle = exports.AccordionWidgetImageHeight = exports.AccordionWidgetCardStyle = exports.ButtonWidgetSize = void 0;
var commonTypes_1 = require("./Config/BlockConfig/commonTypes");
var ButtonWidgetSize;
(function (ButtonWidgetSize) {
    ButtonWidgetSize["Small"] = "small";
    ButtonWidgetSize["Medium"] = "medium";
    ButtonWidgetSize["Large"] = "large";
})(ButtonWidgetSize || (exports.ButtonWidgetSize = ButtonWidgetSize = {}));
var AccordionWidgetCardStyle;
(function (AccordionWidgetCardStyle) {
    AccordionWidgetCardStyle["Simple"] = "simple";
    AccordionWidgetCardStyle["Visual"] = "visual";
})(AccordionWidgetCardStyle || (exports.AccordionWidgetCardStyle = AccordionWidgetCardStyle = {}));
var AccordionWidgetImageHeight;
(function (AccordionWidgetImageHeight) {
    AccordionWidgetImageHeight["Small"] = "small";
    AccordionWidgetImageHeight["Medium"] = "medium";
    AccordionWidgetImageHeight["Large"] = "large";
})(AccordionWidgetImageHeight || (exports.AccordionWidgetImageHeight = AccordionWidgetImageHeight = {}));
var AccordionWidgetButtonStyle;
(function (AccordionWidgetButtonStyle) {
    AccordionWidgetButtonStyle["Arrow"] = "arrow";
    AccordionWidgetButtonStyle["Expand"] = "expand";
    AccordionWidgetButtonStyle["Label"] = "label";
})(AccordionWidgetButtonStyle || (exports.AccordionWidgetButtonStyle = AccordionWidgetButtonStyle = {}));
var AccordionWidgetButtonAlignment;
(function (AccordionWidgetButtonAlignment) {
    AccordionWidgetButtonAlignment["Left"] = "left";
    AccordionWidgetButtonAlignment["Right"] = "right";
})(AccordionWidgetButtonAlignment || (exports.AccordionWidgetButtonAlignment = AccordionWidgetButtonAlignment = {}));
var isSourceStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Source;
};
exports.isSourceStyle = isSourceStyle;
var isSplashStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Splash;
};
exports.isSplashStyle = isSplashStyle;
var isAcceptStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Accept;
};
exports.isAcceptStyle = isAcceptStyle;
var isQuoteStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Quote;
};
exports.isQuoteStyle = isQuoteStyle;
