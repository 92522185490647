"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_transition_group_1 = require("react-transition-group");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var dynamic_1 = require("@vanilla-extract/dynamic");
var pages_common_1 = require("@qwilr/pages-common");
var styles = __importStar(require("@CommonFrontend/AgreementView/AgreementNavigationDrawer.css"));
var AgreementNavigationDrawer = function (_a) {
    var isOpen = _a.isOpen, navItems = _a.navItems, onClick = _a.onClick, close = _a.close, onDrawerToggle = _a.onDrawerToggle, agreementTitle = _a.agreementTitle;
    (0, react_1.useEffect)(function () {
        onDrawerToggle(isOpen);
    }, [isOpen, onDrawerToggle]);
    (0, react_1.useEffect)(function () {
        if (!isOpen) {
            return;
        }
        var handler = function (ev) {
            if (ev.key === utils_1.KeyType.Escape) {
                close();
            }
        };
        document.addEventListener("keydown", handler);
        var firstNavElement = document.getElementsByClassName("modal-nav-menu-item")[0];
        firstNavElement === null || firstNavElement === void 0 ? void 0 : firstNavElement.focus();
        return function () {
            document.removeEventListener("keydown", handler);
        };
    }, [close, isOpen]);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: styles.outerDrawer({ isOpen: isOpen }) },
            react_1.default.createElement(kaleidoscope_1.Layer, { offset: 2 }, function (_a) {
                var _b;
                var zIndex = _a.zIndex;
                return (react_1.default.createElement("div", { className: styles.drawer({ isOpen: isOpen }), style: (0, dynamic_1.assignInlineVars)((_b = {}, _b[styles.layerZIndex] = "".concat(zIndex), _b)) },
                    react_1.default.createElement("div", { className: styles.drawerContent },
                        react_1.default.createElement(react_transition_group_1.Transition, { in: isOpen, mountOnEnter: true, unmountOnExit: true, onEnter: utils_1.forceReflow, timeout: 500 }, function (status) { return (react_1.default.createElement("nav", { className: styles.drawerItem({ status: status }) },
                            react_1.default.createElement("div", { className: styles.title },
                                react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, agreementTitle)),
                            react_1.default.createElement("div", null, navItems.map(function (item) { return (react_1.default.createElement(pages_common_1.MenuItemButton, { key: item.idx, label: item.content, onClick: function () { return onClick(item.idx); }, className: "modal-nav-menu-item", useBoldLabels: false })); })))); }))));
            }))));
};
exports.default = AgreementNavigationDrawer;
